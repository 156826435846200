"use client";

import {
	DetailedHTMLProps,
	FC,
	InputHTMLAttributes,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import mailcheck from "mailcheck";
import Label from "./Label";
import isValidEmail from "utils/isValidEmail";
import { usePasswordRequirements } from "utils/usePasswordRequirements";
import constants from "utils/constants";
import ShowPasswordIcon from "public/show-password.svg";
import HidePasswordIcon from "public/hide-password.svg";
import QuestionMarkIcon from "public/question-mark.svg";
import { Tooltip2 } from "elements/Tooltip";

export interface TextFieldProps
	extends DetailedHTMLProps<
		InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	> {
	label?: string;
	labelEl?: FC;
	numeric?: boolean;
	max?: number;
	fmt?: "numeric";
	labelBeside?: boolean;
	containerClassName?: string;
	onMailCheckSuggestionClick?: (suggestion: string) => void;
	small?: boolean;
	passwordRequirements?: boolean;
	innerRightLabel?: string;
	tooltipText?: string;
}

const TextField: FC<TextFieldProps> = ({
	label,
	labelBeside = false,
	labelEl: UserLabelEl,
	onMailCheckSuggestionClick,
	numeric = false,
	max = undefined,
	fmt = undefined,
	passwordRequirements = false,
	containerClassName,
	required,
	small,
	innerRightLabel,
	tooltipText,
	...inputProps
}) => {
	const [suggestion, setSuggestion] = useState<string>();
	const [additionalProps, setAdditionalProps] = useState<
		Record<string, string | number | boolean>
	>({});
	const [showPassword, setShowPassword] = useState(false);
	const [inputType, setInputType] = useState("text");

	const useMailcheck = !!onMailCheckSuggestionClick;

	const LabelEl = UserLabelEl || Label;

	const ref = useRef<HTMLInputElement>(null);

	const value = inputProps.value || ref.current?.value;

	const isPasswordField = inputProps.type === "password";

	const className = useMemo(() => {
		let val = "";
		if (label) val += " with-label";
		if (small) val += " small";
		if (inputProps.className) val += " " + inputProps.className;
		if (inputProps.type === "password") val += " " + "input-password";

		return val;
	}, [label, small, inputProps.className, inputProps.type]);

	const { allRequirementsMet } = usePasswordRequirements(value as string);

	useEffect(() => {
		if (inputProps.type) {
			setInputType(inputProps.type);
		}
	}, [inputProps.type]);

	useEffect(() => {
		if (inputProps.type === "password") {
			if (showPassword) {
				setInputType("text");
			} else {
				setInputType("password");
			}
		}
	}, [inputProps.type, showPassword]);

	useEffect(() => {
		if (inputProps.type !== "password" || !usePasswordRequirements) return;

		const findNearestFormAncestor = (
			el: HTMLElement | null
		): HTMLFormElement | null => {
			if (!el) return null;

			if (el.tagName === "FORM") {
				return el as HTMLFormElement;
			}

			return findNearestFormAncestor(el.parentElement);
		};

		const form = findNearestFormAncestor(ref.current);

		if (!form) return;

		const passwordInputs = Array.from(form.querySelectorAll(".input-password"));

		// Check that this is either the first of 2 or second of 3 password input
		const isFirstPasswordInput =
			passwordInputs.length &&
			((passwordInputs.length === 2 && passwordInputs[0] === ref.current) ||
				(passwordInputs.length === 3 && passwordInputs[1] === ref.current));

		const isSecondPasswordInput =
			passwordInputs.length &&
			((passwordInputs.length === 2 && passwordInputs[1] === ref.current) ||
				(passwordInputs.length === 3 && passwordInputs[2] === ref.current));

		// If this is the first password input, check if all requirements are met
		if (isFirstPasswordInput) {
			if (allRequirementsMet) {
				setAdditionalProps(prevProps => ({
					...prevProps,
					"data-password-valid": true,
				}));
			} else {
				setAdditionalProps(prevProps => ({
					...prevProps,
					"data-password-valid": false,
				}));
			}
		} else if (isSecondPasswordInput) {
			// If this is the second password input, check if the values match
			if (!inputProps.value) {
				setAdditionalProps(prevProps => ({
					...prevProps,
					"data-password-valid": false,
				}));
				return;
			}

			const firstPasswordInput =
				passwordInputs.length === 2
					? passwordInputs[0]
					: passwordInputs.length === 3
						? passwordInputs[1]
						: null;

			if (!firstPasswordInput) return;

			if (firstPasswordInput.getAttribute("value") === ref.current?.value) {
				setAdditionalProps(prevProps => ({
					...prevProps,
					"data-password-valid": true,
				}));
			} else {
				setAdditionalProps(prevProps => ({
					...prevProps,
					"data-password-valid": false,
				}));
			}
		}
	}, [
		allRequirementsMet,
		inputProps.type,
		inputProps.value,
		usePasswordRequirements,
	]);

	useEffect(() => {
		if (
			inputProps.type !== "password" ||
			!inputProps.value ||
			!ref.current ||
			!usePasswordRequirements
		) {
			return;
		}

		const findNearestFormAncestor = (
			el: HTMLElement | null
		): HTMLFormElement | null => {
			if (!el) return null;

			if (el.tagName === "FORM") {
				return el as HTMLFormElement;
			}

			return findNearestFormAncestor(el.parentElement);
		};

		const form = findNearestFormAncestor(ref.current);

		if (!form) return;

		const passwordInputs = Array.from(
			form.querySelectorAll("input[type=password]")
		);

		// Check that this is either the first of 2 or second of 3 password input
		const isFirstPasswordInput =
			passwordInputs.length &&
			((passwordInputs.length === 2 && passwordInputs[0] === ref.current) ||
				(passwordInputs.length === 3 && passwordInputs[1] === ref.current));

		const isSecondPasswordInput =
			passwordInputs.length &&
			((passwordInputs.length === 2 && passwordInputs[1] === ref.current) ||
				(passwordInputs.length === 3 && passwordInputs[2] === ref.current));

		const el = ref.current;

		const blurHandler = () => {
			if (!el) return;
			if (isFirstPasswordInput) {
				if (!allRequirementsMet) {
					el.style.borderColor = constants.COLORS.terracotta;
				}
			}

			if (isSecondPasswordInput) {
				const firstPasswordInput =
					passwordInputs.length === 2
						? passwordInputs[0]
						: passwordInputs.length === 3
							? passwordInputs[1]
							: null;

				if (!firstPasswordInput) return;

				if (firstPasswordInput.getAttribute("value") !== el.value) {
					el.style.borderColor = constants.COLORS.terracotta;
				}
			}
		};

		const focusHandler = () => {
			if (!el) return;
			el.style.borderColor = "";
		};

		el.addEventListener("blur", blurHandler);
		el.addEventListener("focus", focusHandler);

		return () => {
			if (!el) return;

			el.removeEventListener("blur", blurHandler);
			el.removeEventListener("focus", focusHandler);
		};
	}, [
		inputProps.type,
		inputProps.value,
		allRequirementsMet,
		usePasswordRequirements,
	]);

	useEffect(() => {
		if (ref.current && useMailcheck) {
			if (ref.current.value === "") {
				setSuggestion(undefined);
				return;
			}

			mailcheck.run({
				email: ref.current.value,
				suggested: (s: { full: string; [key: string]: any }) => {
					const formattedEmailRegex = /.+@.+\..+/i;
					if (formattedEmailRegex.test(ref.current!.value)) {
						setSuggestion(s.full);
					}
				},
				empty: () => {
					setSuggestion(undefined);
				},
			});
		}
	}, [ref.current?.value, useMailcheck]);

	if (numeric && inputProps.onChange) {
		const oldOnChange = inputProps.onChange;

		inputProps.onChange = e => {
			if (e.target.value) {
				e.target.value = e.target.value.replace(/\D/g, "");

				if (max && parseInt(e.target.value) > max) e.target.value = String(max);

				if (fmt === "numeric") {
					const int = parseInt(e.target.value);
					if (!isNaN(int)) {
						e.target.value = parseInt(e.target.value).toLocaleString();
					}
				}
			}
			oldOnChange(e);
		};
	}

	useEffect(() => {
		const input = ref.current;
		if (input?.type !== "email" || !value || !input.setCustomValidity) return;

		if (!isValidEmail(value as string)) {
			input.setCustomValidity("Please enter a valid email");
		} else {
			input?.setCustomValidity("");
		}
	}, [value]);

	useEffect(() => {
		const input = ref.current;
		if (input?.type !== "tel" || !value || !input.setCustomValidity) return;
		if ((value as string).length === 14) {
			input.setCustomValidity("");
		} else {
			input.setCustomValidity("Please enter a valid 10 digit phone number");
		}
	}, [value]);

	return (
		<div
			className={containerClassName}
			style={{
				display: "flex",
				flexDirection: labelBeside ? "row" : "column",
			}}>
			{label && (
				<div className="label-container">
					<LabelEl className="input-label" required={required}>
						{label}
					</LabelEl>
					{tooltipText && (
						<Tooltip2 label={label} text={tooltipText}>
							<QuestionMarkIcon />
						</Tooltip2>
					)}
				</div>
			)}
			<div className="text-field-input-wrapper">
				<input
					ref={ref}
					{...inputProps}
					{...additionalProps}
					type={inputType}
					className={className}
					required={required}
				/>
				{isPasswordField && (
					<div
						className="text-field-show-password-container"
						onClick={() => setShowPassword(prev => !prev)}>
						{showPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
					</div>
				)}
				{!isPasswordField && innerRightLabel && (
					<div className="text-field-input-inner-right-label">
						<p>{innerRightLabel}</p>
					</div>
				)}
			</div>
			{useMailcheck && suggestion && (
				<p className="input-email-suggestion sm">
					Did you mean{" "}
					<span
						className="color-azure no-translate"
						onClick={() => {
							onMailCheckSuggestionClick(suggestion);
							setSuggestion("");
						}}>
						{suggestion}
					</span>
					?
				</p>
			)}
		</div>
	);
};

export default TextField;
